export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sevenRoomsVenueId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        address
        city
        state
        zipcode
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
        }
        marketId
        market {
          id
          name
        }
        categories {
          items {
            category {
              id
              name
              sortOrder
              status
            }
          }
          nextToken
        }
        atmospheres {
          items {
            atmosphere {
              id
              name
            }
          }
          nextToken
        }
        cuisines {
          items {
            cuisine {
              id
              name
            }
          }
          nextToken
        }
        awards {
          items {
            award {
              id
              name
              primaryText
              secondaryText
              icon
            }
          }
          nextToken
        }
        tags {
          items {
            tag {
              id
              name
            }
          }
          nextToken
        }
        seatingOptions {
          items {
            seatingOption {
              id
              name
            }
          }
          nextToken
        }
        priceRange
        images
        imagesSmall
        imagesFull
        timezoneName
        isSignatureCollection
        isInsider
        geocode {
          latitude
          longitude
        }
        venueType
        experienceId
        salesStartDate
        salesEndDate
      }
      nextToken
    }
  }
`;
export const listPremierLocations = /* GraphQL */ `
  query ListPremierLocations(
    $filter: ModelPremierLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremierLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subtitle
        sortOrder
        chefName
        images
        description
        spotlight
        status
        isActive
        venues {
          items {
            venue {
              id
              name
              city
              state
              neighborhood {
                id
                name
              }
              marketId
              images
              imagesSmall
              imagesFull
              priceRange
              isActive
              isTest
              badgeText
              specialEventDate
              isHidden
              eventSalesClosed
              eventSoldOut
              isSoldOut
              sortOrder
              eventDates
              venueType
              cuisines {
                items {
                  id
                  cuisine {
                    id
                    name
                  }
                }
              }
              experienceId
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listMarkets = /* GraphQL */ `
  query ListMarkets(
    $filter: ModelMarketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarkets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const getMarket = /* GraphQL */ `
  query GetMarket(
    $id: ID!
    $searchVenuesFilter: SearchableVenueFilterInput
    $sort: SearchableVenueSortInput
  ) {
    getMarket(id: $id) {
      id
      name
      neighborhoods {
        items {
          name
          id
          isActive
        }
      }
    }
    searchVenues(filter: $searchVenuesFilter, sort: $sort) {
      items {
        id
        name
        sevenRoomsVenueId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
        }
        marketId
        market {
          id
          name
        }
        geocode {
          latitude
          longitude
        }
        categories {
          items {
            category {
              id
              name
              description
              sortOrder
              status
              isHighlighted
            }
          }
          nextToken
        }
        atmospheres {
          items {
            atmosphere {
              id
              name
            }
          }
          nextToken
        }
        cuisines {
          items {
            cuisine {
              id
              name
            }
          }
          nextToken
        }
        awards {
          items {
            award {
              id
              name
              primaryText
              secondaryText
              icon
            }
          }
          nextToken
        }
        tags {
          items {
            tag {
              id
              name
            }
          }
          nextToken
        }
        seatingOptions {
          items {
            seatingOption {
              id
              name
            }
          }
          nextToken
        }
        priceRange
        images
        imagesSmall
        imagesFull
        timezoneName
        sortOrder
        specialEventDate
        eventSalesClosed
        eventSoldOut
        isSoldOut
        isInsider
        isTest
        badgeText
        isSignatureCollection
        venueType
        experienceId
        eventDates
        salesStartDate
      }
      nextToken
      total
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          allergy {
            id
            name
          }
        }
      }
      diets {
        items {
          diet {
            id
            name
          }
        }
      }
      hidePhoneNumberAlert
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
    }
  }
`;

export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      name
      sevenRoomsVenueId
      experienceId
      description
      websiteUrl
      menuUrl
      startOfDay
      dineDash
      bookingPolicy
      paymentPolicy
      phoneNumber
      city
      state
      zipcode
      address
      googlePlaceId
      neighborhoodId
      neighborhood {
        id
        name
        marketId
      }
      marketId
      market {
        id
        name
      }
      categories {
        items {
          id
          category {
            id
            name
            sortOrder
            status
          }
        }
        nextToken
      }
      atmospheres {
        items {
          id
          venueId
          atmosphereId
          atmosphere {
            id
            name
          }
        }
        nextToken
      }
      cuisines {
        items {
          id
          venueId
          cuisineId
          cuisine {
            id
            name
            images
          }
        }
        nextToken
      }
      awards {
        items {
          id
          venueId
          awardId
          award {
            id
            name
            primaryText
            secondaryText
            icon
            status
          }
        }
        nextToken
      }
      tags {
        items {
          id
          venueId
          tagId
          tag {
            id
            name
          }
        }
        nextToken
      }
      seatingOptions {
        items {
          id
          venueId
          seatingOptionId
          seatingOption {
            id
            name
          }
        }
        nextToken
      }
      priceRange
      images
      imagesSmall
      imagesFull
      timezoneName
      geocode {
        latitude
        longitude
      }
      specialEventDate
      eventSalesClosed
      eventSoldOut
      isSoldOut
      eventDates
      salesStartDate
      salesEndDate
      badgeText
      isTest
      isSignatureCollection
      isInsider
      isActive
      venueType
      partners {
        items {
          partner {
            name
            icon
          }
        }
      }
      relatedVenues {
        items {
          venue {
            id
            name
          }
          id
          relatedVenue {
            name
            images
            venueType
            salesStartDate
            salesEndDate
            city
            state
            eventDates
            eventSoldOut
            isSoldOut
            timezoneName
            id
            badgeText
            isHidden
            isCardholderExclusive
            description
            venueType
            partners {
              items {
                partner {
                  icon
                  name
                }
              }
            }
            isTest
          }
        }
      }
    }
  }
`;

export const getVenueBySlug = /* GraphQL */ `
  query VenuesByVenueSlug($venueSlug: String) {
    venuesByVenueSlug(venueSlug: $venueSlug) {
      items {
        id
        name
      }
    }
  }
`;

export const searchVenues = /* GraphQL */ `
  query SearchVenues(
    $filter: SearchableVenueFilterInput
    $sort: SearchableVenueSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchVenues(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        normalizedName
        sevenRoomsVenueId
        experienceId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
        }
        marketId
        market {
          id
          name
        }
        geocode {
          latitude
          longitude
        }
        categories {
          items {
            category {
              id
              name
              sortOrder
              status
            }
          }
          nextToken
        }
        atmospheres {
          items {
            atmosphere {
              id
              name
            }
          }
          nextToken
        }
        cuisines {
          items {
            cuisine {
              id
              name
            }
          }
          nextToken
        }
        awards {
          items {
            award {
              id
              name
              primaryText
              secondaryText
              icon
            }
          }
          nextToken
        }
        tags {
          items {
            tag {
              id
              name
            }
          }
          nextToken
        }
        seatingOptions {
          items {
            seatingOption {
              id
              name
            }
          }
          nextToken
        }
        priceRange
        images
        imagesSmall
        imagesFull
        timezoneName
        sortOrder
        specialEventDate
        salesStartDate
        eventSalesClosed
        eventSoldOut
        eventDates
        badgeText
        experienceId
        venueType
        sortOrder
        salesStartDate
        salesEndDate
        isSoldOut
        isCardholderExclusive
        isSignatureCollection
        isHidden
        isInsider
        isTest
        partners {
          items {
            partner {
              name
              icon
            }
          }
        }
        relatedVenues {
          items {
            venue {
              id
              name
            }
            id
            relatedVenue {
              name
              images
              venueType
              city
              state
              eventDates
              eventSoldOut
              isSoldOut
              timezoneName
              id
              badgeText
              description
              isTest
              partners {
                items {
                  partner {
                    icon
                    name
                  }
                }
              }
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const searchVenuesQuery = /* GraphQL */ `
  query SearchVenuesQuery(
    $input: VenueSearchInput
    $filter: SearchableVenueFilterInput
  ) {
    searchVenuesQuery(input: $input, filter: $filter) {
      error
      success
      items {
        id
        name
        normalizedName
        sevenRoomsVenueId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        specialEventDate
        salesStartDate
        eventSalesClosed
        eventSoldOut
        isSoldOut
        badgeText
        isInsider
        isSignatureCollection
        isHidden
        neighborhoodId
        neighborhood {
          id
          name
        }
        marketId
        market {
          id
          name
        }
        geocode {
          latitude
          longitude
        }
        categories {
          items {
            category {
              id
              name
              sortOrder
              status
            }
          }
          nextToken
        }
        atmospheres {
          items {
            atmosphere {
              id
              name
            }
          }
          nextToken
        }
        cuisines {
          items {
            cuisine {
              id
              name
            }
          }
          nextToken
        }
        awards {
          items {
            award {
              id
              name
              primaryText
              secondaryText
              icon
            }
          }
          nextToken
        }
        tags {
          items {
            tag {
              id
              name
            }
          }
          nextToken
        }
        seatingOptions {
          items {
            seatingOption {
              id
              name
            }
          }
          nextToken
        }
        priceRange
        images
        timezoneName
        sortOrder
        venueType
        experienceId
        salesStartDate
        salesEndDate
      }
    }
  }
`;

export const listSpecialOccasions = /* GraphQL */ `
  query ListSpecialOccasions(
    $filter: ModelSpecialOccasionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOccasions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const listAtmospheres = /* GraphQL */ `
  query ListAtmospheres(
    $filter: ModelAtmosphereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAtmospheres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const listCuisines = /* GraphQL */ `
  query ListCuisines(
    $filter: ModelCuisineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCuisines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        images
      }
      nextToken
    }
  }
`;

export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        sortOrder
        status
        isActive
        isHighlighted
      }
      nextToken
    }
  }
`;
