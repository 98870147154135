import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import clsx from 'clsx';

import {
  actionSetSearchCachedQuery,
  actionSetSearchCurrentInputValue,
  actionSetSearchCurrentQuery,
} from '../../store/Search/SearchAction';
import {
  actionMarketListRequest,
  actionNavigateToSearch,
} from '../../store/Markets/MarketsAction';
import { actionAccoladeListRequest } from '../../store/Accolades/AccoladesAction';
import { actionCuisineListRequest } from '../../store/Cuisines/CuisinesAction';
import Cuisines from './Cuisines';
import Neighborhoods from './Neighborhoods';
import Accolades from './Accolades';
import FilledButton from '../../components/core/FilledButton';
import PageSection from '../../components/core/PageSection';
import GalleryList from '../../components/home/GalleryList';
import MarketSelector from './MarketSelector';
import Typography from '../../components/overrides/Typography';
import desktopHeroImg from '../../assets/images/homepage-hero-desktop.png';
import mobileHeroImg from '../../assets/images/homepage-hero-mobile.png';
import { useViewport } from '../../utils/useViewport';
import { useAuth } from '../../utils/useAuth';
import { useMarkets } from '../../utils/useMarkets';
import { useSaveUserCallback } from '../../components/profile/helpers';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import {
  HOME_VIEW_ALL_BUTTON_TEXT_PREFIX,
  SEARCH_ACTION_BUTTON_TEXT,
  LEARN_MORE_BUTTON_TEXT,
  SIGNATURE_COLLECTION_TEXT,
  ABOUT_THE_TEXT,
  EVENT_CAROUSEL_TITLE,
} from '../../assets/copy';
import CommonLoading from '../common/CommonLoading';
import EntertainmentBanner from '../../components/banner/EntertainmentBanner';
import generateSafeHtmlTags from '../../utils/generateSafeHtmlTags';
import CallToActionSection from './CallToActionSection';
import DirectionalButton from '../../components/buttons/DirectionalButton';
import TableParameter from '../venue/TableParameter';
import useFabMethods from '../../utils/useFABMethods';
import FindRestaurantSection from './FindRestaurantSection';
import DiscoverSection from './DiscoverSection';
import ExclusiveAlert from './ExclusiveAlert';
import EventsCarousel from './EventsCarousel';
import { actionGetEventsRequest } from '../../store/Venues/VenuesAction';
import { trackClick } from '../../utils/useOneTag';
import { clearPermalinkRouteRedirect, getPermalinkRouteRedirect } from '../../utils/sessionStorageHelper';
import { getPermalinkRouteRedirectLocation } from '../onboarding/onboardingHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 44,
    },
  },
  img: {
    maxWidth: '100%',
  },
  heroImg: {
    backgroundImage: `linear-gradient(rgba(16, 37, 63, 0.68) 0% 100%, ${theme.palette.blue[40]} 100% 100%), url(${mobileHeroImg}) `,
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `linear-gradient(rgba(16, 37, 63, 0.68) 0% 100%, ${theme.palette.blue[40]} 100% 100%), url(${desktopHeroImg}) `,
    },
    [theme.breakpoints.up('xl')]: {
      height: 350,
    },
  },
  heroEventImg: {
    backgroundImage: ({ mobileHeroEventImg }) =>
      `linear-gradient(rgba(16, 37, 63, 0.68) 0% 100%, ${theme.palette.blue[40]} 100% 100%), url(${mobileHeroEventImg}) `,
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 337,
    [theme.breakpoints.up('lg')]: {
      backgroundImage: ({ desktopHeroEventImg }) =>
        `linear-gradient(rgba(16, 37, 63, 0.68) 0% 100%, ${theme.palette.blue[40]} 100% 100%), url(${desktopHeroEventImg}) `,
    },
    [theme.breakpoints.up('xl')]: {
      height: 350,
    },
  },
  heroContent: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '30px 20px',
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginTop: 0,
    },
  },
  description: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
      maxWidth: 544,
    },
  },
  headerButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '9px 29px',
      maxWidth: 256,
    },
  },
  capitalSection: {
    padding: theme.spacing(4, 0),
    background: theme.palette.gray[5],
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(8, 0, 6),
    },
    width: '100%',
  },
  highlightedSection: {
    background: theme.palette.charcoal[60],
  },
  otherSection: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(7),
    },
    width: '100%',
  },
  label: {
    color: theme.palette.primary.main,
  },
  viewAll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(4, 0, 4),
  },
  viewAllButton: {
    padding: 0,
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

const widthBreakpoint = 960;

const Home = () => {
  const config = useSelector((state) => state.config);
  const classes = useStyles({
    mobileHeroEventImg: config.SPECIAL_EVENT_BANNER_MOBILE_IMAGE_URL,
    desktopHeroEventImg: config.SPECIAL_EVENT_BANNER_DESKTOP_IMAGE_URL,
  });
  const { width } = useViewport();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const { stepUp } = useSelector((state) => state.reservations);
  const { markets, categories, mounted } = useMarkets();
  const {
    user,
    signinWithAuthCode,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  const shouldOnboard = useOnboardingRedirect();
  const { navigationMenuVisible } = useSelector((state) => state.appData);
  const [openSearchOverlay, setOpenSearchOverlay] = useState(false);
  const { overlayVisible } = useSelector((state) => state.search);
  const [openExclusiveAlert, setOpenExclusiveAlert] = useState(false);
  const fabMethods = useFabMethods();
  const { selectedDate, selectedTime, selectedGuests } = useSelector(
    (state) => state?.availability
  );
  const { events } = useSelector((state) => state?.venues);
  const [hasUpdatedMarket, setHasUpdatedMarket] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ariaLabel = 'select city';


  useEffect(() => {
    if (stepUp?.shouldRedirect) return;

    if (assertion != null && !user.session.isAuthenticated) {
      signinWithJwt(assertion);
    } else {
      signinWithAuthCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepUp?.shouldRedirect) {
      history.push('/reserve');
    }
  }, [history, stepUp?.shouldRedirect]);

  useEffect(() => {
    if (stepUp?.shouldRedirect) return;

    // User needs to be authenticated before dispatching
    if (!user.session.isAuthenticated) return;

    setOpenExclusiveAlert(!user.hideExclusiveTaggingAlert);

    dispatch(actionMarketListRequest());
    dispatch(actionAccoladeListRequest());
    dispatch(actionCuisineListRequest());
    dispatch(actionGetEventsRequest({ isTestUser: user.isTest }));
    
    if (user?.marketId && getPermalinkRouteRedirect()) {
      const path = getPermalinkRouteRedirectLocation();
      clearPermalinkRouteRedirect();
      history.push(path);
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.session.isAuthenticated,
    dispatch,
    stepUp?.shouldRedirect,
    user.hideExclusiveTaggingAlert,
    user.isTest,
  ]);

  const isEventOn = config.SPECIAL_EVENT_BANNER_FLAG === 'true';

  const handleGetTicket = useCallback(() => {
    history.push(config.SPECIAL_EVENT_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewAllRestaurants = useCallback((event) => {
    trackClick(event, 'homepage-view-all-restaurants', 'Button');
    history.push('/search', { redirectTo: location });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchOverlay = useCallback(() => {
    setOpenSearchOverlay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseFab = useCallback(() => {
    fabMethods.handleCloseFab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedTime, selectedGuests]);

  const handleNavigateToSearchPage = useCallback((marketChanged = false) => {
    setOpenSearchOverlay(false);
    setIsLoading(true);
    dispatch(actionNavigateToSearch({ history, marketChanged }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToSearchPageFromTableParameter = useCallback(
    () => handleNavigateToSearchPage(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNavigateToSearchPageFromFindSection = useCallback(
    () => handleNavigateToSearchPage(hasUpdatedMarket),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasUpdatedMarket]
  );

  useEffect(() => {
    if (stepUp?.shouldRedirect) return;

    dispatch(actionSetSearchCachedQuery(''));
    dispatch(actionSetSearchCurrentInputValue(''));
    dispatch(actionSetSearchCurrentQuery(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMounted = () =>
    user.session.isAuthenticated && !shouldOnboard && markets?.market?.id;

  const saveUser = useSaveUserCallback(user);

  const updateExclusiveAlertFlag = useCallback(() => {
    saveUser({ hideExclusiveTaggingAlert: true });
  }, [saveUser]);

  const handleOpenExclusive = () => {
    setOpenExclusiveAlert(true);
  };

  const handleCloseExclusive = useCallback(() => {
    setOpenExclusiveAlert(false);
    updateExclusiveAlertFlag();
  }, [updateExclusiveAlertFlag]);

  const showExclusiveTaggingAlert = useCallback(() => {
    return (
      <ExclusiveAlert
        isOpen={openExclusiveAlert}
        handleCloseAlert={handleCloseExclusive}
      />
    );
  }, [handleCloseExclusive, openExclusiveAlert]);

  const showEventHeroPage = useCallback(() => {
    if (isEventOn) {
      return (
        <div className={classes.heroEventImg}>
          <PageSection className={classes.heroContent}>
            <Typography
              variant={width > widthBreakpoint ? 'large1Light' : 'medium3Light'}
              color="secondary"
              component="h1"
              className={classes.title}
              dangerouslySetInnerHTML={generateSafeHtmlTags(
                config.SPECIAL_EVENT_BANNER_TITLE
              )}
            />
            <Typography
              variant={
                width > widthBreakpoint ? 'medium1Normal' : 'small2Normal'
              }
              color="secondary"
              className={classes.description}>
              {config.SPECIAL_EVENT_BANNER_DESCRIPTION}
            </Typography>
            {config.SPECIAL_EVENT_BANNER_ACTION_TEXT && (
              <FilledButton
                text={config.SPECIAL_EVENT_BANNER_ACTION_TEXT}
                style={classes.headerButton}
                onClick={handleGetTicket}
              />
            )}
          </PageSection>
        </div>
      );
    }
    return (
      <div className={classes.heroImg}>
        <PageSection className={classes.heroContent}>
          <DiscoverSection />
          <FindRestaurantSection
            handleSearchOverlay={handleSearchOverlay}
            handleNavigateToSearchPage={
              handleNavigateToSearchPageFromFindSection
            }
          />
        </PageSection>
      </div>
    );
  }, [
    classes.description,
    classes.headerButton,
    classes.heroContent,
    classes.heroEventImg,
    classes.heroImg,
    classes.title,
    config.SPECIAL_EVENT_BANNER_ACTION_TEXT,
    config.SPECIAL_EVENT_BANNER_DESCRIPTION,
    config.SPECIAL_EVENT_BANNER_TITLE,
    handleGetTicket,
    handleNavigateToSearchPageFromFindSection,
    handleSearchOverlay,
    isEventOn,
    width,
  ]);

  const getCategoryStyling = (index, isHighlighted) => {
    const isEvenIndex = index % 2 === 0;
    const sectionClass = isEvenIndex
      ? classes.capitalSection
      : classes.otherSection;

    return isHighlighted
      ? clsx(sectionClass, classes.highlightedSection)
      : sectionClass;
  };

  const getCustomLink = (isHighlighted) => {
    if (isHighlighted) {
      return {
        name: LEARN_MORE_BUTTON_TEXT,
        action: handleOpenExclusive,
      };
    }
  };

  const getCustomAriaLabel = (isHighlighted) => {
    if (isHighlighted) {
      return `${LEARN_MORE_BUTTON_TEXT} ${ABOUT_THE_TEXT} ${SIGNATURE_COLLECTION_TEXT}`;
    }
  };

  return (
    <>
      <CommonLoading active={!isMounted() || isLoading} embedded={true} />
      {isMounted() && !isLoading && !navigationMenuVisible && !overlayVisible && (
        <main className={classes.root}>
          {showExclusiveTaggingAlert()}
          <Box ref={scrollRef}>
            {mounted && (
              <MarketSelector
                list={markets.list}
                ariaLabel={ariaLabel}
                setHasUpdatedMarket={setHasUpdatedMarket}
              />
            )}
            {showEventHeroPage()}
            {config.FEATURE_FLAG_BANNER_EXPLORE_CTA_BUTTON && (
              <CallToActionSection
                marketName={markets.market && markets.market.name}
                onClickHandler={handleViewAllRestaurants}
              />
            )}
            {events?.length > 0 && (
              <EventsCarousel
                isCompact={true}
                list={events}
                hasUpdatedMarket={hasUpdatedMarket}
                title={EVENT_CAROUSEL_TITLE}
                isEventCarousel={true}
                shouldShowDescription={true}
              />
            )}
            {categories &&
              Object.keys(categories).map((key, index) => (
                <div
                  className={getCategoryStyling(
                    index,
                    categories[key].category.isHighlighted
                  )}
                  key={`Category-${index}`}>
                  <GalleryList
                    title={categories[key].category.name}
                    description={categories[key].category.description}
                    list={categories[key].venues}
                    category={categories[key]}
                    role="link"
                    hasUpdatedMarket={hasUpdatedMarket}
                    ariaLabel={getCustomAriaLabel(
                      categories[key].category.isHighlighted
                    )}
                    customLink={getCustomLink(
                      categories[key].category.isHighlighted
                    )}
                  />
                </div>
              ))}
            <Accolades />
            <Cuisines hasUpdatedMarket={hasUpdatedMarket} />
            <Neighborhoods />
            <EntertainmentBanner />
            {!config.FEATURE_FLAG_BANNER_EXPLORE_CTA_BUTTON && (
              <DirectionalButton
                buttonClassName={classes.viewAllButton}
                classContainer={classes.viewAll}
                role="link"
                text={
                  HOME_VIEW_ALL_BUTTON_TEXT_PREFIX +
                  (markets.market && markets.market.name)
                }
                onClickHandler={handleViewAllRestaurants}
                aria-label={
                  HOME_VIEW_ALL_BUTTON_TEXT_PREFIX +
                  (markets.market && markets.market.name)
                }
                data-cy="homepage-view-all-restaurants"
              />
            )}
          </Box>
          {isMounted() && (
            <TableParameter
              isOpen={openSearchOverlay}
              setOpen={setOpenSearchOverlay}
              handleDone={handleNavigateToSearchPageFromTableParameter}
              handleCloseFab={handleCloseFab}
              showMarket={true}
              buttonText={SEARCH_ACTION_BUTTON_TEXT}
              markets={markets}
              isMarketLoaded={mounted}
            />
          )}
        </main>
      )}
    </>
  );
};

export default Home;
